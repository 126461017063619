import React from 'react';
import { Fade } from 'react-slideshow-image';

import SourceEmitter from '../../libs/emitter';

import VideoContainer from 'components/VideoContainer';
import ActiveLink from 'components/ActiveLink';
import WistiaVideo from 'components/WistiaVideo';

import videoThumb from '../../../assets/images/nutrition-hcp-logo.png';
import img1 from '../../../assets/images/ND_Patientsite_homepage_slider1.png';
import img2 from '../../../assets/images/ND_Patientsite_homepage_slider2.png';
import img3 from '../../../assets/images/ND_Patientsite_homepage_slider3.png';
import img1Mobile from '../../../assets/images/ND_Patientsite_homepage_slider1-mobile.png';
import img2Mobile from '../../../assets/images/ND_Patientsite_homepage_slider2-mobile.png';
import img3Mobile from '../../../assets/images/ND_Patientsite_homepage_slider3-mobile.png';
import NutritionDirectVideos from 'libs/videos/nutrition-direct-videos';

// import RecipePDF from '../../../../static/pdfs/NS-05402___Nutrition_Direct_Recipe_Card_Library.pdf';

import './index.scss';

const fadeProperties = {
  // duration: 10000,
  // transitionDuration: 500,
  duration: 4000,
  transitionDuration: 400,
  infinite: true,
  indicators: true,
  arrows: false,
  onChange: (oldIndex, newIndex) => {
    console.log(`fade transition from ${oldIndex} to ${newIndex}`);
  },
};

// For customizations
// https://github.com/femioladeji/react-slideshow#readme

const HBCarousel = ({ className, bg, children }) => {
  // const copyBlock = (
  //   <div className='copy-block-container'>
  //         <h1>Patients are <br /> at the heart</h1>
  //         <p><em>of everything we do</em></p>
  //         <p className='small'>"We put breakthrough science within reach"</p>
  //   </div>
  // )
  const handleVidClick = () => {
    pushEventGA(this.props.EventObject);
    SourceEmitter.emit(`onVideoOpenEvent-nd-pre-operative-video`, {});
  };

  return (
    <div>
      <div className="slide-container">
        <Fade {...fadeProperties}>
          <div className="each-fade hb-slide1">
            <div className="image-container">
              <a href="/nutritiondirect/why-nutrition-direct">
                <img src={img1} className="hide-mobile" />
                <img src={img1Mobile} className="hide-desktop" />
              </a>
            </div>
          </div>

          <div className="each-fade hb-slide2">
            <div className="image-container">
              <a href="/nutritiondirect/recipes">
                <img src={img2} className="hide-mobile" />
                <img src={img2Mobile} className="hide-desktop" />
              </a>
            </div>
          </div>

          <div className="each-fade hb-slide3">
            <div className="image-container">
              <ActiveLink
                VideoId="nd-pre-operative-video"
                VideoLink="https://fast.wistia.net/embed/iframe/jzbz9tj4os?videoFoam=true"
                EventObject={{
                  category: 'Julie Johannes Video',
                  action: 'Click',
                  label: 'Julie Johannes Video',
                }}
              >
                <img src={img3} className="hide-mobile" />
                <img src={img3Mobile} className="hide-desktop" />
              </ActiveLink>
            </div>
          </div>
        </Fade>
      </div>
      <VideoContainer
        poster={videoThumb}
        url="https://fast.wistia.net/embed/iframe/jzbz9tj4os?videoFoam=true"
        smallThumb
        carousel
        videoId="nd-pre-operative-video"
        dlURL=""
        video={NutritionDirectVideos[3]}
      />
    </div>
  );
};

export default HBCarousel;
