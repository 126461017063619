import React, { useContext, useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import SourceEmitter from 'libs/emitter';
import ContentBlock from 'components/Content/ContentBlock';
import CarouselNDPat from 'components/CarouselNDPat';
import BrandCTA from 'components/BrandCta';

import './index.scss';

const IndexPage = props => {
  let Subscription = null;

  // const [directLinkVideo, setDirectLinkVideo] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [initialHeight, setInitialHeight] = useState('100vh');

  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  const metaData = appConfigs?.metaData?.nutritionPatient;

  const setResponseParams = () => {
    const responseObj = window.location.hash
      .substr(1)
      .split('&')
      .map(el => el.split('='))
      .reduce((pre, cur) => {
        pre[cur[0]] = cur[1];
        return pre;
      }, {});
    // setDirectLinkVideo(responseObj.video);

    if (responseObj.physicianLocator) {
      SourceEmitter.emit('SpecialistLocatorTrigger', 'open');
    }
  };

  const triggerHashChange = () => {
    const currentHash = window?.location.hash.substring(
      1,
      window?.location.hash.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    setHasLoaded(true);
    window.addEventListener('hashchange', triggerHashChange, false);

    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    setResponseParams();

    return () => {
      // eslint-disable-next-line no-unused-expressions
      window?.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
      setInitialHeight('auto');
    };
  }, []);

  // const brandNames = {
  //   NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
  //   BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
  //   NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  // };

  const renderHeroSection = () => {
    return (
      <ContentBlock>
        <Grid
          fluid
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
          }}
        >
          <div>
            <CarouselNDPat />

            <Row between="xs">
              <Col xs={12} md={6}>
                <BrandCTA
                  SpecialistLink
                  href="#"
                  LinkCaption="FIND A PHYSICIAN NEAR YOU"
                >
                  FIND A PHYSICIAN NEAR YOU
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="http://www.facebook.com/nascobal"
                  LinkCaption="FOLLOW US ON FACEBOOK"
                  ExternalLink
                >
                  FOLLOW US ON FACEBOOK
                </BrandCTA>
              </Col>
            </Row>
          </div>
        </Grid>
      </ContentBlock>
    );
  };

  const renderISIAnchor = () => {
    return (
      <ScrollableAnchor id="isi_anchor">
        <div />
      </ScrollableAnchor>
    );
  };

  const renderPage = () => {
    return (
      hasLoaded && (
        <div id="nutrition-patient-home">
          {renderHeroSection()}
          {renderISIAnchor()}
        </div>
      )
    );
  };

  const bodyHeight = {
    // height: initialHeight,
  };
  return (
    <Layout indication={indication} className="nutrition-patient-home">
      <Seo {...metaData} />
      <div className="body-content-container" style={bodyHeight}>
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default IndexPage;
